<template>
  <div v-if="dataResponse != null">
    <v-select id="register-orderStatus" v-model="orderStatus" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name" :clearable="false" :placeholder="`${$t('Change')}${$t('Order Status')}`" :options="optionOrderStatus"
      :reduce="optionOrderStatus => optionOrderStatus.value" @input="changeStatus($event)" />
    <b-list-group class="mb-2">
      <b-list-group-item>
        {{ this.$t('OrderCode') }} :
        {{ subStringOrderId(dataResponse.orderCode) }}
      </b-list-group-item>
      <b-list-group-item>
        {{ this.$t('OrderId') }} :
        {{ subStringOrderId(dataResponse._id).toUpperCase() }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.priceShipment > 0">
        {{ this.$t('Shipping cost') }} :
        {{ (dataResponse.priceShipment / 100) | numberWithCommas }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.priceCod > 0">
        {{ this.$t('Destination delivery fee') }} :
        {{ (dataResponse.priceCod / 100) | numberWithCommas }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.coupon.price > 0">
        {{ this.$t('Coupon') }} :
        {{ (dataResponse.coupon.price / 100) | numberWithCommas }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.sale > 0">
        {{ this.$t('Price to pay') }} :
        {{
    (dataResponse.currencyId === null
      ? dataResponse.sale / 100
      : (dataResponse.sale_currency / 100).toFixed(2)) | numberWithCommas
  }}{{ dataResponse.currencySymbol }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.pointSum > 0">
        {{ this.$t('Points received') }} :
         {{ (dataResponse.pointSum / 100) | numberWithCommas }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.creditSum > 0">
        {{ this.$t('Commission for referrals') }} :
         {{ (dataResponse.creditSum / 100) | numberWithCommas }}
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.coupon">
        <b-row class="align-items-center">
          <b-col>
            {{ this.$t('Coupon') }} : {{ showFromCurrentLanguage(dataResponse.coupon.name) }}
          </b-col>
          <b-col>
            <v-select id="register-orderStatus" v-model="couponId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name" :clearable="true" :placeholder="`${$t('Change')}${$t('Coupon')}`" :options="respDataCoupon"
              :reduce="respDataCoupon => respDataCoupon._id" @input="changeCoupon($event)" />
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item v-if="dataResponse.branch">
        <b-row class="align-items-center">
          <b-col>
            {{ this.$t('Branch') }} : {{ showFromCurrentLanguage(dataResponse.branch.name) }}
          </b-col>
          <b-col>
            <v-select id="register-orderStatus" v-model="branchId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name" :clearable="true" :placeholder="`${$t('Change')}${$t('Branch')}`" :options="respDataBranch"
              :reduce="respDataBranch => respDataBranch._id" @input="changeBranch($event)" />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <div v-if="dataResponse != null" class="mb-2">
      <b-button v-if="editAddressStatus === false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="'warning'"
        size="sm" @click="editAddressStatus = !editAddressStatus">
        {{ $t('Edit address') }}
        <feather-icon icon="ShoppingBagIcon" size="12" />
      </b-button>
      <b-button v-if="editAddressStatus === true" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="'success'"
        size="sm" @click="saveAddress">
        {{ $t('Save address') }}
        <feather-icon icon="ShoppingBagIcon" size="12" />
      </b-button>
    </div>
    <b-media vertical-align="top" class="mb-2">
      <h4 class="media-heading">
        {{ $t('Recipient') }} : {{ dataResponse['address']['name'] }}
      </h4>
      <div v-if="editAddressStatus === true">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="$t('Address')" label-for="register-Address">
              <b-form-input id="register-Address" v-model="address" name="register-Address"
                :placeholder="$t('Address')" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('Country')" label-for="register-countryId">
              <v-select id="register-countryId" v-model="countryId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name" :clearable="false" :placeholder="$t('Country')" :options="OptionCountry"
                :reduce="OptionCountry => OptionCountry" @input="getProvince($event)" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('State')" label-for="register-countryId">
              <v-select id="register-stateId" v-model="stateId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name" :clearable="false" :placeholder="$t('State')" :options="OptionState"
                :reduce="OptionState => OptionState" @input="getDistrict($event)" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('City')" label-for="register-cityId">
              <v-select id="register-cityId" v-model="cityId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name" :clearable="false" :placeholder="$t('City')" :options="OptionCity"
                :reduce="OptionCity => OptionCity" @input="getSubDistrict($event)" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group :label="$t('Sub City')" label-for="register-subCityId">
              <v-select id="register-subCityId" v-model="subCityId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name" :clearable="false" :placeholder="$t('Sub City')" :options="OptionSubCity"
                :reduce="OptionSubCity => OptionSubCity" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label="$t('Phone Number')" label-for="register-phoneNumber">
              <b-form-input id="register-phoneNumber" v-model="tel" name="register-phoneNumber"
                :placeholder="$t('Phone Number')" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label="$t('ZipCode')" label-for="register-ZipCode">
              <b-form-input id="register-ZipCode" v-model="zipCode" name="register-ZipCode"
                :placeholder="$t('ZipCode')" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card-text v-if="dataResponse.address.countryId === null || dataResponse.address.countryId.id === 219"
          class="mb-0">
          {{ $t('Phone Number') }} : {{ dataResponse.address.tel }},
          {{ $t('Address') }} : {{ dataResponse.address.address }},
          {{
    dataResponse.address.subdistrictId != null
      ? `${$t('Subdistrict')} ${showFromCurrentLanguage(
        dataResponse.address.subdistrictId.name,
      )}, `
      : ''
  }}
          {{
      dataResponse.address.districtId != null
        ? `${$t('District')} ${showFromCurrentLanguage(
          dataResponse.address.districtId.name,
        )}, `
        : ''
    }}
          {{
      dataResponse.address.provinceId != null
        ? `${$t('Province')} ${showFromCurrentLanguage(
          dataResponse.address.provinceId.name,
        )}, `
        : ''
    }}
          {{ dataResponse.address.zipCode }}
        </b-card-text>
        <b-card-text v-else class="mb-0">
          {{ $t('Phone Number') }} : {{ dataResponse.address.tel }},
          {{ $t('Address') }} : {{ dataResponse.address.address }},
          {{
    dataResponse.address.subCityId != null
      ? `${showFromCurrentLanguage(
        dataResponse.address.subCityId.name,
      )}, `
      : ''
  }}
          {{
      dataResponse.address.cityId != null
        ? `${showFromCurrentLanguage(
          dataResponse.address.cityId.name,
        )}, `
        : ''
    }}
          {{
      dataResponse.address.stateId != null
        ? `${showFromCurrentLanguage(
          dataResponse.address.stateId.name,
        )}, `
        : ''
    }}
          {{
      dataResponse.address.countryId != null
        ? `${dataResponse.address.countryId.name}, `
        : ''
    }}
          {{ dataResponse.address.zipCode }}
        </b-card-text>
      </div>
    </b-media>
    <div v-if="dataResponse != null" class="float-right mb-2">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mr-2"
        @click="editStatus = !editStatus">
        {{ $t('Price to pay') }} : {{ priceToPay | numberWithCommas }}
        {{ dataResponse.currencySymbol }}
      </b-button>
      <b-button v-if="editStatus === false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="'warning'" size="sm"
        @click="editStatus = !editStatus">
        {{ $t('Edit order') }}
        <feather-icon icon="ShoppingBagIcon" size="12" />
      </b-button>
      <b-button v-if="editStatus === true" v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="'success'" size="sm"
        @click="saveOrder">
        {{ $t('Save order') }}
        <feather-icon icon="ShoppingBagIcon" size="12" />
      </b-button>
    </div>
    <b-table ref="refUserListTable" striped responsive class="position-relative" :items="products"
      :fields="tableColumns" primary-key="_id" show-empty :empty-text="$t('No matching records found')">
      <template #cell(_id)="data">
        <b-card-text>{{ data.index + 1 }}</b-card-text>
      </template>

      <template #cell(image)="data">
        <b-row>
          <b-img v-if="data.item.product.images.length > 0" :src="data.item.product.images[0]" fluid thumbnail
            width="100px" :alt="'Users_' + data.item._id" />
          <b-img v-else :src="require('@/assets/images/blank/no_image.png')" fluid thumbnail width="100px"
            :alt="'Users_' + data.item._id" />
        </b-row>
      </template>

      <template #cell(option)="data">
        <b-card-text>{{ nameOption(data.item.productOptionId) }}</b-card-text>
      </template>

      <template #cell(price)="data">
        <b-card-text v-if="dataResponse.currencyId === null">{{
    (data.item.price / 100) | numberWithCommas
  }}</b-card-text>

        <b-card-text v-else>{{ (data.item.price_currency / 100).toFixed(2) | numberWithCommas
          }}{{ dataResponse.currencySymbol }}</b-card-text>
      </template>

      <template #cell(product)="data">
        <b-card-text>{{
    data.item.product.optionNameTranslate === 'multiple'
      ? showFromCurrentLanguage(data.item.product.nameTranslate)
      : data.item.product.name
  }}</b-card-text>
      </template>

      <template #cell(amount)="data">
        <b-card-text v-if="editStatus === false">{{
    data.item.amount
  }}</b-card-text>
        <cleave v-if="editStatus === true" id="register-amount" v-model="data.item.amount" class="form-control w-full"
          :raw="false" :options="optionCleave.number" :placeholder="$t('Amount')" />
      </template>

      <template #cell(action)="data">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="sm"
          @click="deleteData(data.item._id)">
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>

      <template #cell(total)="data">
        <b-card-text>{{
          totalProduct(data.item) | numberWithCommas
          }}</b-card-text>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BImg,
  BMedia,
  BTable,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

const STORE_MODULE_NAME_COUPON = 'coupon'
const STORE_MODULE_NAME_BRANCH = 'branch'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BMedia,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    Cleave,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'dataResponse',
    event: 'update:data-response',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    dataResponse: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      products: [],
      countryId: null,
      stateId: null,
      cityId: null,
      subCityId: null,
      orderStatus: '',
      zipCode: '',
      tel: '',
      address: '',
      editStatus: false,
      editAddressStatus: false,
      couponId: null,
      branchId: null,
    }
  },
  computed: {
    OptionCountry() {
      return (
        store.state[this.storeModuleName].respDataCountry.map(e => ({
          _id: e._id,
          id: e.id,
          name: e.name,
        })) || []
      )
    },
    OptionState() {
      return (
        store.state[this.storeModuleName].respDataState.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              id: e.id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            id: e.id,
            name: e.name[0].value,
          }
        }) || []
      )
    },
    OptionCity() {
      return (
        store.state[this.storeModuleName].respDataCity.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              id: e.id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            id: e.id,
            name: e.name[0].value,
          }
        }) || []
      )
    },
    OptionSubCity() {
      return (
        store.state[this.storeModuleName].respDataSubCity.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              id: e.id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            id: e.id,
            name: e.name[0].value,
          }
        }) || []
      )
    },
    priceToPay() {
      let price = 0
      for (let i = 0; i < this.products.length; i += 1) {
        const item = this.products[i]
        if (this.dataResponse.currencyId === null) {
          price
            += item.price * parseFloat(item.amount.toString().replaceAll(',', ''))
        } else {
          price
            += item.price_currency
            * parseFloat(item.amount.toString().replaceAll(',', ''))
        }
      }
      return (price / 100).toFixed(2)
    },
    optionOrderStatus() {
      return [
        {
          name: this.$t('waiting_to_check_the_order'.toUpperCase()),
          value: 'waiting_to_check_the_order',
        },
        {
          name: this.$t('to_be_paid'.toUpperCase()),
          value: 'to_be_paid',
        },
        {
          name: this.$t('pending_review'.toUpperCase()),
          value: 'pending_review',
        },
        {
          name: this.$t('to_be_shipped'.toUpperCase()),
          value: 'to_be_shipped',
        },
        {
          name: this.$t('something_to_get'.toUpperCase()),
          value: 'something_to_get',
        },
        {
          name: this.$t('success'.toUpperCase()),
          value: 'success',
        },
        {
          name: this.$t('cancel'.toUpperCase()),
          value: 'cancel',
        },
        {
          name: this.$t('refund'.toUpperCase()),
          value: 'refund',
        },
        {
          name: this.$t('fail'.toUpperCase()),
          value: 'fail',
        },
      ]
    },
    tableColumns() {
      if (this.editStatus === true) {
        return [
          { key: '_id', label: '#', sortable: false },
          { key: 'image', label: this.$t('Image'), sortable: false },
          { key: 'product', label: this.$t('Product name'), sortable: false },
          { key: 'option', label: this.$t('Option'), sortable: false },
          { key: 'price', label: this.$t('Price'), sortable: false },
          { key: 'amount', label: this.$t('Amount'), sortable: false },
          { key: 'action', label: this.$t('Action'), sortable: false },
          { key: 'total', label: this.$t('Total'), sortable: false },
        ]
      }
      return [
        { key: '_id', label: '#', sortable: false },
        { key: 'image', label: this.$t('Image'), sortable: false },
        { key: 'product', label: this.$t('Product name'), sortable: false },
        { key: 'option', label: this.$t('Option'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'amount', label: this.$t('Amount'), sortable: false },
        { key: 'total', label: this.$t('Total'), sortable: false },
      ]
    },
    respDataCoupon() {
      return store.state[STORE_MODULE_NAME_COUPON].respData != null
        ? store.state[STORE_MODULE_NAME_COUPON].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)}`,
        }))
        : []
    },
    respDataBranch() {
      return store.state[STORE_MODULE_NAME_BRANCH].respData != null
        ? store.state[STORE_MODULE_NAME_BRANCH].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)}`,
        }))
        : []
    },
  },
  watch: {
    async dataResponse(val) {
      if (val) {
        await this.getCoupon()
        await this.getBranch()
        this.initData()
      }
    },
  },
  async created() {
    await this.getCoupon()
    await this.getBranch()
    this.initData()
  },
  methods: {
    async getBranch() {
      const obj = {
        currentPage: 1,
        pageSize: 0,
        searchQuery: '',
      }

      await store
        .dispatch(`${STORE_MODULE_NAME_BRANCH}/get`, obj)
        .then(result => {

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    async getCoupon() {
      const obj = {
        currentPage: 1,
        pageSize: 0,
        searchQuery: '',
      }

      await store
        .dispatch(`${STORE_MODULE_NAME_COUPON}/get`, obj)
        .then(result => {

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    initData() {
      this.$nextTick(async () => {
        this.dataId = this.dataResponse._id
        this.products = this.dataResponse.products
        this.address = this.dataResponse.address.address
        this.orderStatus = this.dataResponse.orderStatus
        await this.getCountry()

        if (this.dataResponse.address.countryId !== null) {
          this.countryId = this.dataResponse.address.countryId
          console.log('1')
          await this.getProvince(this.countryId)
          if (this.dataResponse.address.stateId !== null) {
            const { name } = this.dataResponse.address.stateId
            const locale = this.showFromCurrentLanguage(name).toString()
            this.stateId = {
              ...this.dataResponse.address.stateId,
              name: locale,
            }
            console.log('2')
            await this.getDistrict(this.stateId)
            if (this.dataResponse.address.cityId !== null) {
              const { name } = this.dataResponse.address.cityId
              const locale = this.showFromCurrentLanguage(name).toString()
              this.cityId = {
                ...this.dataResponse.address.cityId,
                name: locale,
              }
              console.log('3')
              await this.getSubDistrict(this.cityId)
              if (this.dataResponse.address.subCityId !== null) {
                const { name } = this.dataResponse.address.subCityId
                const locale = this.showFromCurrentLanguage(name).toString()
                this.subCityId = {
                  ...this.dataResponse.address.subCityId,
                  name: locale,
                }
              } else {
                this.subCityId = null
              }
            } else {
              this.cityId = null
              store.commit(`${this.storeModuleName}/UPDATE_SUBDISTRICT`, [])
            }
          } else {
            this.stateId = null
            store.commit(`${this.storeModuleName}/UPDATE_DISTRICT`, [])
          }
        } else {
          this.countryId = null
          store.commit(`${this.storeModuleName}/UPDATE_PROVINCE`, [])
        }
        if (this.dataResponse.coupon.couponId !== null) {
          const index = this.respDataCoupon.findIndex(e => e._id.toString() === this.dataResponse.coupon.couponId.toString())
          if (index > -1) {
            this.couponId = this.respDataCoupon[index]._id
          }
        } else {
          this.couponId = null
        }

        if (this.dataResponse.branch.branchId !== null) {
          const index = this.respDataBranch.findIndex(e => e._id.toString() === this.dataResponse.branch.branchId.toString())
          if (index > -1) {
            this.branchId = this.respDataBranch[index]._id
          }
        } else {
          this.branchId = null
        }

        this.tel = this.dataResponse.address.tel
        this.zipCode = this.dataResponse.address.zipCode
      })
    },
    getCountry() {
      store
        .dispatch(`${this.storeModuleName}/getCountry`, {})
        // eslint-disable-next-line no-unused-vars
        .then(result => { })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getProvince(item) {
      // this.stateId = null
      // this.cityId = null
      // this.subCityId = null
      // store.commit(`${this.storeModuleName}/UPDATE_PROVINCE`, [])
      // store.commit(`${this.storeModuleName}/UPDATE_DISTRICT`, [])
      // store.commit(`${this.storeModuleName}/UPDATE_SUBDISTRICT`, [])
      if (item.id) {
        store
          .dispatch(`${this.storeModuleName}/getProvince`, { countryId: item.id })
          // eslint-disable-next-line no-unused-vars
          .then(result => { })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    getDistrict(item) {
      // this.cityId = null
      // this.subCityId = null
      // store.commit(`${this.storeModuleName}/UPDATE_DISTRICT`, [])
      // store.commit(`${this.storeModuleName}/UPDATE_SUBDISTRICT`, [])
      if (item.id) {
        store
          .dispatch(`${this.storeModuleName}/getDistrict`, {
            countryId: this.countryId.id,
            provinceId: item.id,
          })
          // eslint-disable-next-line no-unused-vars
          .then(result => { })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    getSubDistrict(item) {
      // this.subCityId = null
      // store.commit(`${this.storeModuleName}/UPDATE_SUBDISTRICT`, [])
      store
        .dispatch(`${this.storeModuleName}/getSubDistrict`, {
          countryId: this.countryId.id,
          provinceId: this.stateId.id,
          districtId: item.id,
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => { })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    totalProduct(product) {
      let price = 0
      if (this.dataResponse.currencyId === null) {
        price
          += product.price
          * parseFloat(product.amount.toString().replaceAll(',', ''))
      } else {
        price
          += product.price_currency
          * parseFloat(product.amount.toString().replaceAll(',', ''))
      }
      return (price / 100).toFixed(2)
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            const index = this.products.findIndex(
              e => e._id.toString() === id.toString(),
            )
            if (index > -1) {
              this.products.splice(index, 1)
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    changeBranch(branchId) {
      store
        .dispatch(`${this.storeModuleName}/saveBranch`, {
          id: this.dataId,
          branchId,
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.$emit('update:data-response', result.data.data)
          // this.dataResponse = result.data.data

          this.dataLoading = false
        })
        .catch(error => {
          this.dataLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    changeCoupon(couponId) {
      store
        .dispatch(`${this.storeModuleName}/saveCoupon`, {
          id: this.dataId,
          couponId,
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.$emit('update:data-response', result.data.data)
          // this.dataResponse = result.data.data

          this.dataLoading = false
        })
        .catch(error => {
          this.dataLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    changeStatus(type) {
      store
        .dispatch(`${this.storeModuleName}/changeStatus`, {
          id: this.dataId,
          type,
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.$emit('update:data-response', result.data.data)
          // this.dataResponse = result.data.data

          this.dataLoading = false
        })
        .catch(error => {
          this.dataLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveOrder() {
      store
        .dispatch(`${this.storeModuleName}/saveOrder`, {
          id: this.dataId,
          products: this.products.map(e => ({
            ...e,
            amount: e.amount.toString().replaceAll(',', ''),
          })),
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.$emit('update:data-response', result.data.data)
          // this.dataResponse = result.data.data

          this.dataLoading = false
        })
        .catch(error => {
          this.dataLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveAddress() {
      store
        .dispatch(`${this.storeModuleName}/saveAddress`, {
          id: this.dataId,
          address: {
            address: this.address,
            tel: this.tel,
            countryId: this.countryId !== null ? this.countryId._id : null,
            stateId: this.stateId !== null ? this.stateId._id : null,
            cityId: this.cityId !== null ? this.cityId._id : null,
            subCityId: this.subCityId !== null ? this.subCityId._id : null,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.$emit('update:data-response', result.data.data)
          // this.dataResponse = result.data.data

          this.dataLoading = false
        })
        .catch(error => {
          this.dataLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nameOption(data) {
      let name = ''
      for (let index = 0; index < data.length; index += 1) {
        name = name.toString() + (index === 0 ? '' : ',') + data[index].name
      }
      return name
    },
    subStringOrderId(data) {
      return data.substr(16, data.length)
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>
